/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import type { FC } from 'react';
import React, { memo, useMemo, useCallback, useState, useRef } from 'react';
import { styled } from '@compiled/react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { N0, N50, N500 } from '@atlaskit/theme/colors';
import Popup from '@atlaskit/popup';
import Blanket from '@atlaskit/blanket';
import Button from '@atlaskit/button/standard-button';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import ShortcutIcon from '@atlaskit/icon/core/shortcut';
import AddIcon from '@atlaskit/icon/utility/add';
import type { CardClient } from '@atlaskit/link-provider';
import { SmartCardProvider } from '@atlaskit/link-provider';
import type { LinkPickerProps, LinkPickerPlugin } from '@atlaskit/link-picker';
import { LazyLinkPicker } from '@atlaskit/link-picker/lazy';
import { useSmartLinkLifecycleAnalytics } from '@atlaskit/link-analytics';
import { withAnalyticsContext } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';

import {
	ExpandableMenuItem,
	ExpandableMenuItemTrigger,
	ExpandableMenuItemContent,
} from '@atlassian/navigation-system/side-nav/expandable-menu-item';
import { AtlassianLinkPickerPlugin } from '@atlassian/link-picker-atlassian-plugin';

import { LoadableAfterPaint, LoadableLazy } from '@confluence/loadable';
import { useStoreOptions } from '@confluence/content-smartlinks';
import { useSearchProvider } from '@confluence/editor-features';
import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { SpacePersistentCollapsibleSectionLoader } from '@confluence/collapsible-section/entry-points/SpacePersistentCollapsibleSectionLoader';
import { EmptySidebarCollapsibleSectionLoader } from '@confluence/collapsible-section/entry-points/EmptySidebarCollapsibleSectionLoader';
import { useSpacePersistentExpansion } from '@confluence/collapsible-section/entry-points/useSpacePersistentExpansion';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import type { SpaceShortcutsSpaceNavigationQuery_spaceSidebarLinks_quick as SpaceNavigationQueryShortcutsType } from './__types__/SpaceShortcutsSpaceNavigationQuery';
import { ShortcutsHistory } from './ShortcutsHistory';
import { useAddShortcut } from './useShortcuts';
import { isPageSSRd } from './ShortcutsItem';

const ShortcutsDragContainer = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ShortcutsDragContainer" */ './ShortcutsDragContainer'
			)
		).ShortcutsDragContainer,
});

export const MigrateShortcutsDialog = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-MigrateShortcutsDialog" */ './MigrateShortcutsDialog'
			)
		).MigrateShortcutsDialog,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerContainer = styled.div({
	cursor: 'default',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LightGreyButton = styled.button({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	height: '32px !important',
	width: '32px',
	background: token('color.background.neutral', 'rgba(9, 30, 66, 0.08)'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LightGreyIconWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover svg': {
		color: token('color.icon', N500),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinksSectionContainer = styled.div({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a:visited, a:focus, a:active': {
		textDecoration: 'none',
		color: token('color.link.pressed', N500),
	},
});

const i18n = defineMessages({
	spaceShortcutsHeader: {
		id: 'space-shortcuts.shortcuts.header',
		defaultMessage: 'Shortcuts',
		description: "Header for a space's list of shortcuts",
	},
	spaceShortcutsAddLinkTooltip: {
		id: 'space-shortcuts.shortcuts.add.tooltip',
		defaultMessage: 'Add shortcut',
		description: 'Tooltip for button to add a shortcut using the link picker',
	},
	shortcutsTooltip: {
		id: 'space-shortcuts.side-nav-collapsible-header.tooltip',
		defaultMessage: 'Quick links to Confluence or web content',
		description: 'Tooltip when hovering over shortcuts space sidebar collapsible section',
	},
	noShortcutsMessage: {
		id: 'space-shortcuts.no-shortcuts.message',
		defaultMessage: 'No shortcuts in this space',
		description: 'Message when shortcuts section is empty for admins',
	},
	shortcutsMigrationMessage: {
		id: 'space-shortcuts.move.message',
		defaultMessage: 'Shortcuts are moving to the content tree as Smart Links.',
		description: 'Message to inform users that shortcuts are being migrated to Smart Links',
	},
	shortcutsMigrationButton: {
		id: 'space-shortcuts.move.button',
		defaultMessage: 'Move now',
		description: 'Button to migrate shortcuts to Smart Links',
	},
	shortcutsMigrationAddLinkTooltip: {
		id: 'space-shortcuts.move.add.tooltip',
		defaultMessage: 'Use Smart Links in the content tree to add new links.',
		description:
			'Tooltip for the disabled add shortcut button during the shortcuts migration process',
	},
	shortcutsMigrationBannerLearnMore: {
		id: 'space-shortcuts.banner.learn-more',
		defaultMessage: 'Learn more',
		description: 'Learn more button for the shortcuts migration that leads to the community post',
	},
});

type ShortcutsSectionProps = {
	isSpaceAdmin: boolean;
	spaceId: string | null;
	spaceKey: string;
	links: SpaceNavigationQueryShortcutsType[];
	client?: CardClient | undefined;
};

const ShortcutsSectionComponent: FC<ShortcutsSectionProps & WithFlagsProps> = memo(
	({ flags, isSpaceAdmin, links, spaceId, spaceKey }) => {
		const { formatMessage } = useIntl();
		const isNav4Enabled = useIsNav4Enabled();
		const shortcutsHistoryRef = useRef(new ShortcutsHistory());
		const [isPopupOpen, setIsPopupOpen] = useState(false);

		const handlePopupClose = useCallback(() => {
			setIsPopupOpen(false);
		}, [setIsPopupOpen]);

		const openPopup = useCallback(() => {
			setIsPopupOpen(!isPopupOpen);
		}, [isPopupOpen, setIsPopupOpen]);

		const { addShortcut, undoAddShortcut } = useAddShortcut(links, flags, shortcutsHistoryRef);

		const { linkCreated } = useSmartLinkLifecycleAnalytics();

		const isMigrateShortcutsEnabled = useBooleanFeatureFlag(
			'confluence.frontend.migrate-shortcuts-to-smart-links-in-tree',
		);

		// collapsed by default if there are no shortcuts
		const { isExpanded, onExpansionToggle } = useSpacePersistentExpansion(
			spaceKey,
			'shortcuts',
			links.length === 0,
		);

		const onSubmit: LinkPickerProps['onSubmit'] = useCallback(
			(payload, analytic) => {
				const { url, displayText: title } = payload;
				// hides previous success flag so that only one is on the screen at a time
				const lastMovement = shortcutsHistoryRef.current.peek();
				if (lastMovement) {
					void flags.hideFlag(lastMovement.flagId);
				}

				const item = {
					url,
					title,
				};
				addShortcut(item);

				// needed for undo functionality
				shortcutsHistoryRef.current.push({
					undo: () => undoAddShortcut(item as any),
					lastDeletedItem: item,
				});
				handlePopupClose();

				linkCreated(payload, analytic);
			},
			[handlePopupClose, addShortcut, undoAddShortcut, flags, linkCreated],
		);

		const searchProvider = useSearchProvider();
		const plugins: [LinkPickerPlugin] = useMemo(
			() => [
				new AtlassianLinkPickerPlugin({
					searchProvider,
					activityClientEndpoint: `${window.location.origin}/gateway/api/graphql`,
				}),
			],
			[searchProvider],
		);

		/* eslint-disable jsx-a11y/no-autofocus */
		// Popup was stealing focus away from the LinkPicker autofocus
		const renderAddShortcutButton = useCallback(
			(tooltipContent?: any) => (
				<Popup
					autoFocus={false}
					isOpen={isPopupOpen}
					onClose={() => handlePopupClose()}
					content={({ update }) => (
						<LazyLinkPicker
							plugins={plugins}
							onSubmit={onSubmit}
							onCancel={handlePopupClose}
							onContentResize={update}
						/>
					)}
					placement="bottom-start"
					trigger={(triggerProps) => {
						return isNav4Enabled ? (
							<Tooltip
								content={<FormattedMessage {...i18n.spaceShortcutsAddLinkTooltip} />}
								position="top"
								hideTooltipOnMouseDown
							>
								<IconButton
									icon={AddIcon}
									label={<FormattedMessage {...i18n.spaceShortcutsAddLinkTooltip} />}
									onClick={() => setIsPopupOpen((v) => !v)}
									spacing="compact"
									appearance="subtle"
									{...triggerProps}
								/>
							</Tooltip>
						) : (
							<TriggerContainer onClick={(e) => e.preventDefault()}>
								<Tooltip
									content={
										isMigrateShortcutsEnabled
											? formatMessage(i18n.shortcutsMigrationAddLinkTooltip)
											: tooltipContent
									}
									position="top"
									hideTooltipOnMouseDown
								>
									<Button
										{...triggerProps}
										component={LightGreyButton}
										appearance="subtle"
										iconBefore={
											<LightGreyIconWrapper>
												<EditorAddIcon
													label=""
													primaryColor={
														isPopupOpen
															? token('color.icon.selected', N0)
															: token('color.icon.subtle', N50)
													}
												/>
											</LightGreyIconWrapper>
										}
										aria-label={tooltipContent}
										isSelected={isPopupOpen}
										data-styled-is-selected={isPopupOpen}
										onClick={openPopup}
										isDisabled={isMigrateShortcutsEnabled || Boolean(process.env.REACT_SSR)}
									/>
								</Tooltip>
								<Blanket
									isTinted={false}
									shouldAllowClickThrough={!isPopupOpen}
									onBlanketClicked={handlePopupClose}
								/>
							</TriggerContainer>
						);
					}}
				/>
			),
			[
				isPopupOpen,
				handlePopupClose,
				plugins,
				onSubmit,
				isMigrateShortcutsEnabled,
				formatMessage,
				openPopup,
				isNav4Enabled,
			],
		);

		// Show nothing if the user is not a space admin and there are no shortcuts
		if (!isSpaceAdmin && links.length == 0) {
			return null;
		}

		const menuContent =
			links.length > 0 ? (
				<LinksSectionContainer>
					<ShortcutsDragContainer
						isSpaceAdmin={isSpaceAdmin}
						links={links}
						spaceId={spaceId}
						spaceKey={spaceKey}
						shortcutsHistoryRef={shortcutsHistoryRef}
					/>
				</LinksSectionContainer>
			) : (
				<EmptySidebarCollapsibleSectionLoader>
					<FormattedMessage {...i18n.noShortcutsMessage} />
				</EmptySidebarCollapsibleSectionLoader>
			);

		if (isNav4Enabled) {
			return (
				<ExpandableMenuItem isExpanded={isExpanded} onExpansionToggle={onExpansionToggle}>
					<ExpandableMenuItemTrigger
						elemBefore={<ShortcutIcon label="" color="currentColor" />}
						actionsOnHover={isSpaceAdmin && renderAddShortcutButton()}
					>
						<FormattedMessage {...i18n.spaceShortcutsHeader} />
					</ExpandableMenuItemTrigger>
					<ExpandableMenuItemContent>{menuContent}</ExpandableMenuItemContent>
				</ExpandableMenuItem>
			);
		}

		return (
			<SpacePersistentCollapsibleSectionLoader
				renderAfterIconButton={() =>
					isSpaceAdmin ? (
						<FormattedMessage {...i18n.spaceShortcutsAddLinkTooltip}>
							{renderAddShortcutButton}
						</FormattedMessage>
					) : null
				}
				headerTitle={formatMessage(i18n.spaceShortcutsHeader)}
				sectionName="shortcuts"
				spaceKey={spaceKey || ''}
				tooltipContent={formatMessage(i18n.shortcutsTooltip)}
			>
				{menuContent}
			</SpacePersistentCollapsibleSectionLoader>
		);
	},
);

const ShortcutsSectionWrapper = withAnalyticsContext({
	attributes: {
		location: 'confluenceShortcuts',
	},
})(withFlags(ShortcutsSectionComponent));

export const ShortcutsSection: FC<ShortcutsSectionProps> = (props) => {
	const initialState = window['__SSR_SHORTCUTS__'];
	const storeData = useStoreOptions(initialState, isPageSSRd);
	const storeOptions = isPageSSRd() ? storeData : {};

	return (
		<SmartCardProvider client={props.client} authFlow="disabled" {...storeOptions}>
			<ShortcutsSectionWrapper {...props} />
		</SmartCardProvider>
	);
};
